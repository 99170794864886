import { FormState } from '../contexts/types';

const timeInMonths = (
  state: FormState,
  type: 'applicant' | 'coApplicant',
  entity: 'currentEmployer' | 'currentAddress'
) => {
  const years = parseInt(state.data.info[type][entity].time.years) || 0;
  const months = parseInt(state.data.info[type][entity].time.months) || 0;

  return years * 12 + months;
};

export default timeInMonths;
