import React, { useContext, useState, useEffect } from 'react';
import { IconButton } from '../../components/Inputs/Buttons';
import CircularProgress from '@material-ui/core/CircularProgress';
import SearchIcon from '@material-ui/icons/Search';
import getInfoByZip from './ZipCodeLookupService';
import { FormContext } from '../../contexts';
import { Alert } from '../../components/DataDisplay';

interface Props {
  addressType: 'current' | 'previous';
  zipCode: string | null;
  setCities: React.Dispatch<
    React.SetStateAction<{ current: string[]; previous: string[] }>
  >;
  cityPath: string;
  statePath: string;
}

export default function ZipCodeLookup({
  addressType,
  zipCode,
  cityPath,
  statePath,
  setCities,
}: Props) {
  const [, setState] = useContext(FormContext);
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setErrorState] = useState({
    open: false,
    message: '',
  });

  useEffect(() => {
    if (errorState.open) {
      const timer = setTimeout(() => {
        setErrorState({ ...errorState, open: false, message: '' });
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [errorState]);

  const handleZipCodeLookup = () => {
    setIsLoading(true);
    if (zipCode)
      getInfoByZip(zipCode)
        .then(({ normalized, cities }) => {
          setIsLoading(false);
          if (Object.keys(normalized).length !== 0) {
            setState({
              value: normalized.city?.long_name ?? null,
              name: cityPath,
              type: 'text',
            });
            setState({
              value: normalized.state?.long_name ?? null,
              name: statePath,
              type: 'text',
            });
          } else {
            setErrorState({
              open: true,
              message: 'Zip code was not found!',
            });
          }
          if (cities.length) {
            setCities((prevCities) => ({
              ...prevCities,
              [addressType]: cities,
            }));
          }
        })
        .catch(() => {
          setIsLoading(false);
          setErrorState({
            open: true,
            message: 'Bad response from the zip code lookup service!',
          });
        });
  };
  return (
    <>
      <Alert
        errorState={errorState}
        setErrorState={setErrorState}
        severity="error"
      />
      <div>
        {isLoading && (
          <CircularProgress
            style={{ position: 'absolute' }}
            thickness={2.5}
            size={44}
          />
        )}
        <IconButton
          icon={<SearchIcon fontSize="small" />}
          handleFunc={handleZipCodeLookup}
          disabled={zipCode === null || zipCode === undefined}
        />
      </div>
    </>
  );
}
