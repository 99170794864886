import { AddressComponent, GeocodingResult } from '@google/maps';
function fetch_retry(
  url: string,
  options: RequestInit | undefined,
  n: number
): Promise<Response> {
  return new Promise(function (resolve, reject) {
    fetch(url, options)
      .then(resolve) // <--- Much cleaner!
      .catch(function (error) {
        if (n === 1) return reject(error);
        fetch_retry(url, options, n - 1)
          .then(resolve)
          .catch(reject);
      });
  });
}

const isAdministrativeAreaLevel1 = (
  addressComponent: AddressComponent<unknown>
): addressComponent is AddressComponent<'administrative_area_level_1'> =>
  addressComponent.types.includes('administrative_area_level_1');
const isAdministrativeAreaLevel2 = (
  addressComponent: AddressComponent<unknown>
): addressComponent is AddressComponent<'administrative_area_level_2'> =>
  addressComponent.types.includes('administrative_area_level_2');
const isLocality = (
  addressComponent: AddressComponent<unknown>
): addressComponent is AddressComponent<'locality'> =>
  addressComponent.types.includes('locality');
const isPostalCode = (
  addressComponent: AddressComponent<unknown>
): addressComponent is AddressComponent<'postal_code'> =>
  addressComponent.types.includes('postal_code');

const isCountry = (
  addressComponent: AddressComponent<unknown>
): addressComponent is AddressComponent<'country'> =>
  addressComponent.types.includes('country');
interface NormalizedGeocodingResult {
  state?: AddressComponent<'administrative_area_level_1'>;
  county?: AddressComponent<'administrative_area_level_2'>;
  city?: AddressComponent<'locality'>;
  postal_code?: AddressComponent<'postal_code'>;
}

const normalizeGeocodingResult = (result: GeocodingResult) =>
  result.address_components.reduce<NormalizedGeocodingResult>(
    (acc, addressComponent) => {
      if (isAdministrativeAreaLevel1(addressComponent)) {
        return { ...acc, state: addressComponent };
      }
      if (isAdministrativeAreaLevel2(addressComponent)) {
        return { ...acc, county: addressComponent };
      }
      if (isLocality(addressComponent)) {
        return { ...acc, city: addressComponent };
      }
      if (isPostalCode(addressComponent)) {
        return { ...acc, postal_code: addressComponent };
      }
      if (isCountry(addressComponent)) {
        return { ...acc, country: addressComponent };
      }
      return acc;
    },
    {}
  );
export default function getInfoByZip(
  ZIP: string
): Promise<{ normalized: NormalizedGeocodingResult; cities: string[] }> {
  return fetch_retry(
    `https://maps.googleapis.com/maps/api/geocode/json?address=${ZIP}&key=AIzaSyAAqiqrMrgQhdqgbjzFB-L9gVu-Hhv2wzE`,
    {
      credentials: 'omit',
      headers: {
        Accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.5',
        'Sec-Fetch-Dest': 'empty',
        'Sec-Fetch-Mode': 'cors',
        'Sec-Fetch-Site': 'cross-site',
      },
      method: 'GET',
      mode: 'cors',
    },
    5
  )
    .then((response) => response.json())
    .then((response) => {
      if (response.results.length > 0) {
        const normalized = normalizeGeocodingResult(response.results[0]);
        return {
          normalized,
          cities:
            response.results[0]?.postcode_localities ?? [normalized?.city] ??
            [],
        };
      }
      return { normalized: {}, cities: [] };
    });
}
