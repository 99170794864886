import React, { useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import {
  Dialog as MaterialDialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core/';

interface ModalState {
  open: boolean;
  index: null | number;
}

interface Props {
  title: string;
  divId: string;
  content: JSX.Element;
  modalState: ModalState;
  setModalState: (x: ModalState) => void;
}

export default function Dialog({
  title,
  divId,
  content,
  modalState,
  setModalState,
}: Props) {
  const descriptionElementRef = useRef<HTMLElement>(null);

  const handleClose = () => {
    setModalState({ ...modalState, open: false, index: null });
  };

  const handlePrint = () => {
    const divContents = document?.getElementById(divId)?.innerHTML;
    const myWindow = window.open('', '', 'height=500, width=500');

    if (divContents !== undefined) {
      myWindow?.document.write('<!DOCTYPE html>');
      myWindow?.document.write('<html><head><title></title>');
      myWindow?.document.write('</head><body >');
      myWindow?.document.write(divContents);
      myWindow?.document.write('</body></html>');
      myWindow?.document.close();
      myWindow?.print();
    }
  };

  useEffect(() => {
    if (modalState.open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [modalState.open]);

  return (
    <div>
      <MaterialDialog
        open={modalState.open}
        onClose={handleClose}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>
        <DialogContent dividers>{content}</DialogContent>
        <DialogActions>
          <Button onClick={handlePrint} color="primary" variant="contained">
            Print
          </Button>
          <Button onClick={handleClose} color="primary" variant="contained">
            Close
          </Button>
        </DialogActions>
      </MaterialDialog>
    </div>
  );
}
