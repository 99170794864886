import { axiosInstance } from './axiosInstance';

export default async function getDealershipNameById(dealershipId: string) {
  try {
    const response = await axiosInstance.get(
      `/api/dealership_name_by_id/${dealershipId}`
    );
    return response?.data;
  } catch (error) {
    return error;
  }
}
