import React, { useContext } from 'react';
// @ts-ignore
import MuiPhoneNumber from 'material-ui-phone-number';
import { FormContext } from '../../../contexts/';
import { validateSchema } from 'validations';

interface Props {
  label: string;
  required?: boolean;
  name: string;
  value: string | null;
}

export default function PhoneField({
  label,
  required = false,
  name,
  value,
}: Props) {
  const [, setState] = useContext(FormContext);
  const { errors } = validateSchema(
    'new_external_credit_application',
    value,
    name?.split('.')
  );
  const error =
    errors && errors?.length > 0 && value !== undefined && value !== '';
  return (
    <div style={{ position: 'relative' }}>
      <MuiPhoneNumber
        error={error}
        helperText={
          error
            ? errors?.reduce((acc, curr) => acc + '\n' + curr.message, '')
            : undefined
        }
        name={name}
        value={value}
        size="small"
        variant="outlined"
        label={label}
        required={required}
        fullWidth
        placeholder="(XXX) XXX-XXXX"
        onlyCountries={['us']}
        disableCountryCode={true}
        defaultCountry={'us'}
        style={{ backgroundColor: '#FFFFFF', borderRadius: '7px' }}
        onChange={(value: any) => {
          setState({
            value: value !== '' ? value.replace(/[- )(]/g, '') : null,
            name,
            type: 'text',
          });
        }}
      />
    </div>
  );
}
