import { createContext } from 'react';
import { FormState } from './types';

const applicantData = {
  firstName: null,
  middleName: null,
  lastName: null,
  idType: 'driversLicense',
  idNumber: null,
  idIssuedBy: null,
  idExpDate: null,
  idIssuedDate: null,
  dateOfBirth: null,
  ssn: null,
  primaryPhone: null,
  secondaryPhone: null,
  email: null,
  isUSCitizen: 'Yes',
  maritalStatus: 'Married',
  currentAddress: {
    address: null,
    addressNumber: null,
    apt: null,
    city: null,
    state: null,
    zip: null,
    time: { years: null, months: null },
  },
  residentialStatus: 'own',
  rentOrMortgageAmount: 0,
  previousAddress: {
    address: null,
    addressNumber: null,
    apt: null,
    city: null,
    state: null,
    zip: null,
    time: { years: null, months: null },
  },
  employmentStatus: 'Employee',
  currentEmployer: {
    name: null,
    grossMonthlySalary: 0,
    workPhone: null,
    occupationOrJobTitle: null,
    time: { years: null, months: null },
  },
  prevEmployer: {
    name: null,
    occupationOrJobTitle: null,
    time: { years: null, months: null },
  },
  grossMonthlyOtherIncome: 0,
  otherIncomeSource: null,
  retirementIncome: 0,
  sourceOfRetirementIncome: null,
};

export const defaultState = {
  captchaToken: null,
  data: {
    dealershipId: window.location.pathname.split('/').slice(-1).toString(),
    info: {
      creditType: 'individual',
      applicant: applicantData,
      coApplicant: applicantData,
      collateral: {
        vin: null,
        year: null,
        make: null,
        model: null,
        miles: null,
        unitStatus: null,
        MSRP: null,
      },
      tradeIn: {
        vin: null,
        year: null,
        make: null,
        model: null,
        miles: null,
      },
      price: {
        price: null,
        totalCash: null,
        tradeAllowance: null,
      },
      payoff: {
        payoff: null,
        payoffBank: null,
      },
      fees: {
        titleFees: null,
        docFee: null,
      },
      taxes: {
        stateTax: null,
      },
      termsAccepted: false,
    },
  },
};

export const FormContext = createContext<
  [
    FormState,
    (action: {
      value: string | number | null | boolean | object;
      name: string;
      type: string;
    }) => void
  ]
>([defaultState, () => undefined]);
