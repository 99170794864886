import React from "react";
import { Route, Switch } from "react-router-dom";
import { LayoutWrapper } from "../features/LayoutWrapper/";
import { FormContext, RequestContext } from "../contexts/";
import { NotFound } from "../features/NotFound";
import GlobalStateManager from "../utils/GlobalStateManager";
import RequestStateManager from "../utils/RequestStateManager";
import isUrlValid from "../utils/isUrlValid";

function App() {
  const [state, setState] = GlobalStateManager();
  const [requestState, dispatch] = RequestStateManager();

  return (
    <FormContext.Provider value={[state, setState]}>
      <RequestContext.Provider value={[requestState, dispatch]}>
        {isUrlValid() ? (
          <Switch>
            <Route path="/application/new/" component={LayoutWrapper} />
            <Route component={NotFound} />
          </Switch>
        ) : (
          <NotFound />
        )}
      </RequestContext.Provider>
    </FormContext.Provider>
  );
}

export default App;
