import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core/';
import { Room } from '@material-ui/icons';
import React, { useContext } from 'react';
import { Text } from '../../components/DataDisplay';
import { RadioGroup } from '../../components/Inputs';
import {
  ComboField,
  CurrencyField,
  DatePickerField,
  PhoneField,
  SsnField,
  TextField,
} from '../../components/Inputs/Fields';
import { FormContext } from '../../contexts/';
import timeInMonths from '../../utils/timeInMonths';
import States from '../../utils/us-states';
import { ZipCodeLookup } from '../ZipCodeLookup';
import { Id, State } from './types';

interface Props {
  type: 'applicant' | 'coApplicant';
}

export default function ApplicantInfo({ type }: Props) {
  const [state, setState] = useContext(FormContext);
  const [cities, setCities] = React.useState<{
    current: string[];
    previous: string[];
  }>({
    current: [],
    previous: [],
  });
  const [middleNameRequired, setMiddleNameRequired] = React.useState(true);
  return (
    <>
      <div
        style={{
          marginBottom: '10px',
          boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
          borderRadius: '3px',
          padding: '15px',
        }}
      >
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12}>
              <TextField
                label="First Name"
                required={true}
                name={`data.info.${type}.firstName`}
                value={state.data.info[type].firstName}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label="Middle Name"
                required={middleNameRequired}
                disabled={!middleNameRequired}
                name={`data.info.${type}.middleName`}
                value={state.data.info[type].middleName ?? null}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormControlLabel
                onChange={() => {
                  if (middleNameRequired) {
                    setState({
                      value: null,
                      name: `data.info.${type}.middleName`,
                      type: 'text',
                    });
                  }
                  setMiddleNameRequired((prev) => !prev);
                }}
                control={
                  <Checkbox
                    id={'middleNameRequired'}
                    checked={!middleNameRequired}
                  />
                }
                label={
                  <Typography style={{ fontSize: '12px' }}>
                    I don't have a middle name
                  </Typography>
                }
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField
                label="Last Name"
                required={true}
                name={`data.info.${type}.lastName`}
                value={state.data.info[type].lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <ComboField<Id>
                label="ID Type"
                labelProp="type"
                valueProp="value"
                required={true}
                disableClearable={true}
                options={[{ type: 'Drivers License', value: 'driversLicense' }]}
                name={`data.info.${type}.idType`}
                value={state.data.info[type].idType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="ID No."
                required={true}
                name={`data.info.${type}.idNumber`}
                value={state.data.info[type].idNumber}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ComboField<State>
                labelProp="name"
                valueProp="abbreviation"
                label="ID Issued By"
                required={true}
                options={[...States]}
                name={`data.info.${type}.idIssuedBy`}
                value={state.data.info[type].idIssuedBy}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePickerField
                label="ID Issued Date"
                required={true}
                name={`data.info.${type}.idIssuedDate`}
                value={state.data.info[type].idIssuedDate}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePickerField
                label="ID Exp. Date"
                required={true}
                name={`data.info.${type}.idExpDate`}
                value={state.data.info[type].idExpDate}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePickerField
                label="Date of Birth"
                required={true}
                type="birth-date"
                name={`data.info.${type}.dateOfBirth`}
                value={state.data.info[type].dateOfBirth}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SsnField
                label="Soc. Sec. No."
                required={true}
                name={`data.info.${type}.ssn`}
                value={state.data.info[type].ssn}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneField
                label="Primary Phone"
                required={true}
                name={`data.info.${type}.primaryPhone`}
                value={state.data.info[type].primaryPhone}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PhoneField
                label="Secondary Phone"
                name={`data.info.${type}.secondaryPhone`}
                value={state.data.info[type].secondaryPhone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email Address"
                required={true}
                name={`data.info.${type}.email`}
                type="email"
                value={state.data.info[type].email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup
                radioOptions={[
                  {
                    value: 'Yes',
                    label: 'Yes',
                  },
                  {
                    value: 'No',
                    label: 'No',
                  },
                ]}
                row={true}
                label="Is US citizen?"
                required={true}
                name={`data.info.${type}.isUSCitizen`}
                value={state.data.info[type].isUSCitizen ?? 'Yes'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RadioGroup
                radioOptions={[
                  {
                    value: 'Married',
                    label: 'Married',
                  },
                  {
                    value: 'Not married',
                    label: 'Not married',
                  },
                ]}
                row={true}
                label="Marital status"
                required={true}
                name={`data.info.${type}.maritalStatus`}
                value={state.data.info[type].maritalStatus ?? 'Married'}
              />
            </Grid>
            <Grid item xs={12}>
              <Text fontSize={16} fontStyle="italic" text="Current address" />
            </Grid>
            {type === 'coApplicant' && (
              <Grid item xs={12} style={{ margin: '2px' }}>
                <Button
                  onClick={() =>
                    setState({
                      name: 'data.info.coApplicant.currentAddress',
                      value: state.data.info['applicant']?.currentAddress,
                      type: 'object',
                    })
                  }
                  color="primary"
                  size="small"
                  variant="contained"
                >
                  Same as the applicant
                </Button>
              </Grid>
            )}
            <Grid item xs={12} sm={3}>
              <TextField
                label="House Num."
                required={true}
                type="number"
                name={`data.info.${type}.currentAddress.addressNumber`}
                value={state.data.info[type].currentAddress.addressNumber ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Full Street Name"
                required={true}
                name={`data.info.${type}.currentAddress.address`}
                value={state.data.info[type].currentAddress.address ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Apt/Unit"
                name={`data.info.${type}.currentAddress.apt`}
                value={state.data.info[type].currentAddress.apt ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <TextField
                label="Zip"
                required={true}
                name={`data.info.${type}.currentAddress.zip`}
                value={state.data.info[type].currentAddress.zip ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={1}>
              <ZipCodeLookup
                addressType="current"
                setCities={setCities}
                zipCode={state.data.info[type].currentAddress.zip}
                cityPath={`data.info.${type}.currentAddress.city`}
                statePath={`data.info.${type}.currentAddress.state`}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="City"
                required={true}
                name={`data.info.${type}.currentAddress.city`}
                value={state.data.info[type].currentAddress.city ?? ''}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <ComboField<State>
                labelProp="name"
                valueProp="name"
                label="State"
                required={true}
                options={[...States]}
                name={`data.info.${type}.currentAddress.state`}
                value={state.data.info[type].currentAddress.state ?? ''}
              />
            </Grid>
            <Grid item xs={12}>
              <List component="nav" aria-label="main mailbox folders">
                {cities.current.length > 1 &&
                  cities.current.map((city, index) => (
                    <div key={index}>
                      <ListItem
                        button
                        onClick={() =>
                          setState({
                            value: city,
                            name: `data.info.${type}.currentAddress.city`,
                            type: 'text',
                          })
                        }
                      >
                        <ListItemIcon>
                          <Room />
                        </ListItemIcon>
                        <ListItemText>{city}</ListItemText>
                      </ListItem>
                      <Divider />
                    </div>
                  ))}
              </List>
            </Grid>

            <Grid item xs={12}>
              <Text
                fontSize={16}
                fontStyle="italic"
                text="Time at current address"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Years"
                required={true}
                name={`data.info.${type}.currentAddress.time.years`}
                value={state.data.info[type].currentAddress.time.years ?? ''}
                type="number"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Months"
                name={`data.info.${type}.currentAddress.time.months`}
                value={state.data.info[type].currentAddress.time.months ?? ''}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <RadioGroup
                radioOptions={[
                  {
                    value: 'own',
                    label: 'Own',
                  },
                  {
                    value: 'rent',
                    label: 'Rent',
                  },
                  {
                    value: 'withRelatives',
                    label: 'Family',
                  },
                  {
                    value: 'other',
                    label: 'Other',
                  },
                ]}
                row={true}
                label="Residential Status"
                required={true}
                name={`data.info.${type}.residentialStatus`}
                value={state.data.info[type].residentialStatus ?? 'own'}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <CurrencyField
                label="Rent or Mortgage Amount"
                required={true}
                name={`data.info.${type}.rentOrMortgageAmount`}
                value={state.data.info[type].rentOrMortgageAmount}
              />
            </Grid>
            {timeInMonths(state, type, 'currentAddress') < 24 && (
              <>
                <Grid item xs={12}>
                  <Text
                    fontSize={16}
                    fontStyle="italic"
                    text="Previous address"
                  />
                </Grid>
                {type === 'coApplicant' && (
                  <Grid item xs={12} sm={12} style={{ margin: '2px' }}>
                    <Button
                      onClick={() =>
                        setState({
                          name: 'data.info.coApplicant.previousAddress',
                          value: state.data.info['applicant']?.previousAddress,
                          type: 'object',
                        })
                      }
                      color="primary"
                      size="small"
                      variant="contained"
                    >
                      Same as the applicant
                    </Button>
                  </Grid>
                )}
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="House Num."
                    required={true}
                    type="number"
                    name={`data.info.${type}.previousAddress.addressNumber`}
                    value={
                      state.data.info[type].previousAddress.addressNumber ?? ''
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Full Street Name"
                    required={true}
                    name={`data.info.${type}.previousAddress.address`}
                    value={state.data.info[type].previousAddress.address ?? ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Apt/Unit"
                    name={`data.info.${type}.previousAddress.apt`}
                    value={state.data.info[type].previousAddress.apt ?? ''}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    label="Zip"
                    required={true}
                    name={`data.info.${type}.previousAddress.zip`}
                    value={state.data.info[type].previousAddress.zip ?? ''}
                  />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <ZipCodeLookup
                    addressType="previous"
                    zipCode={state.data.info[type].previousAddress.zip}
                    setCities={setCities}
                    cityPath={`data.info.${type}.previousAddress.city`}
                    statePath={`data.info.${type}.previousAddress.state`}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextField
                    label="City"
                    required={true}
                    name={`data.info.${type}.previousAddress.city`}
                    value={state.data.info[type].previousAddress.city ?? ''}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <ComboField<State>
                    labelProp="name"
                    valueProp="name"
                    label="State"
                    required={true}
                    options={[...States]}
                    name={`data.info.${type}.previousAddress.state`}
                    value={state.data.info[type].previousAddress.state ?? ''}
                  />
                </Grid>
                <Grid item xs={12}>
                  <List component="nav" aria-label="main mailbox folders">
                    {cities.previous.length > 1 &&
                      cities.previous.map((city, index) => (
                        <div key={index}>
                          <ListItem
                            button
                            onClick={() =>
                              setState({
                                value: city,
                                name: `data.info.${type}.previousAddress.city`,
                                type: 'text',
                              })
                            }
                          >
                            <ListItemIcon>
                              <Room />
                            </ListItemIcon>
                            <ListItemText>{city}</ListItemText>
                          </ListItem>
                          <Divider />
                        </div>
                      ))}
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <Text
                    fontSize={16}
                    fontStyle="italic"
                    text="Time at previous address"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Years"
                    required={true}
                    name={`data.info.${type}.previousAddress.time.years`}
                    value={
                      state.data.info[type].previousAddress.time.years ?? ''
                    }
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Months"
                    name={`data.info.${type}.previousAddress.time.months`}
                    value={
                      state.data.info[type].previousAddress.time.months ?? ''
                    }
                    type="number"
                  />
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <RadioGroup
                radioOptions={[
                  {
                    value: 'Employee',
                    label: 'Employee',
                  },
                  {
                    value: 'Business owner',
                    label: 'Business owner',
                  },
                  {
                    value: 'Retired',
                    label: 'Retired',
                  },
                ]}
                row={true}
                label="Employment status"
                required={true}
                name={`data.info.${type}.employmentStatus`}
                value={state.data.info[type].employmentStatus ?? 'Employee'}
              />
            </Grid>
            {state.data.info[type].employmentStatus !== 'Retired' && (
              <React.Fragment>
                <Grid item xs={12}>
                  <Text
                    fontSize={16}
                    fontStyle="italic"
                    text="Current employer"
                  />
                </Grid>
                <Grid item xs={12} sm={8}>
                  <TextField
                    label="Name"
                    required={true}
                    name={`data.info.${type}.currentEmployer.name`}
                    value={state.data.info[type].currentEmployer.name}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <CurrencyField
                    label="Gross Monthly Salary"
                    required={true}
                    name={`data.info.${type}.currentEmployer.grossMonthlySalary`}
                    value={
                      state.data.info[type].currentEmployer.grossMonthlySalary
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <PhoneField
                    label="Work Phone"
                    required={true}
                    name={`data.info.${type}.currentEmployer.workPhone`}
                    value={state.data.info[type].currentEmployer.workPhone}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Occupation/Job Title"
                    required={true}
                    name={`data.info.${type}.currentEmployer.occupationOrJobTitle`}
                    value={
                      state.data.info[type].currentEmployer.occupationOrJobTitle
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    fontSize={16}
                    fontStyle="italic"
                    text="Time at current employer"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Years"
                    required={true}
                    name={`data.info.${type}.currentEmployer.time.years`}
                    value={state.data.info[type].currentEmployer.time.years}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Months"
                    name={`data.info.${type}.currentEmployer.time.months`}
                    value={state.data.info[type].currentEmployer.time.months}
                    type="number"
                  />
                </Grid>
              </React.Fragment>
            )}
            {state.data.info[type].employmentStatus === 'Retired' && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Text
                      fontSize={16}
                      fontStyle="italic"
                      text="Retirement income"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label="Retirement Income source"
                      name={`data.info.${type}.sourceOfRetirementIncome`}
                      value={state.data.info[type].sourceOfRetirementIncome}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyField
                      required
                      label="Retirement income"
                      name={`data.info.${type}.retirementIncome`}
                      value={state.data.info[type].retirementIncome}
                    />
                  </Grid>
                </Grid>
              </>
            )}
            {state.data.info[type].employmentStatus === 'Retired' ||
            timeInMonths(state, type, 'currentEmployer') < 24 ? (
              <>
                <Grid item xs={12}>
                  <Text
                    fontSize={16}
                    fontStyle="italic"
                    text="Previous employer"
                  />
                </Grid>
                <Grid item xs={12} sm={7}>
                  <TextField
                    label="Name"
                    required={true}
                    name={`data.info.${type}.prevEmployer.name`}
                    value={state.data.info[type].prevEmployer.name}
                  />
                </Grid>
                {state.data.info[type].employmentStatus === 'Retired' && (
                  <Grid item xs={12} sm={5}>
                    <CurrencyField
                      label="Gross Monthly Salary"
                      required={true}
                      name={`data.info.${type}.currentEmployer.grossMonthlySalary`}
                      value={
                        state.data.info[type].currentEmployer.grossMonthlySalary
                      }
                    />
                  </Grid>
                )}
                <Grid
                  item
                  xs={12}
                  sm={
                    state.data.info[type].employmentStatus === 'Retired'
                      ? 12
                      : 5
                  }
                >
                  <TextField
                    label="Occupation/Job Title"
                    required={true}
                    name={`data.info.${type}.prevEmployer.occupationOrJobTitle`}
                    value={
                      state.data.info[type].prevEmployer.occupationOrJobTitle
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <Text
                    fontSize={16}
                    fontStyle="italic"
                    text="Time at previous employer"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Years"
                    required={true}
                    name={`data.info.${type}.prevEmployer.time.years`}
                    value={state.data.info[type].prevEmployer.time.years}
                    type="number"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Months"
                    name={`data.info.${type}.prevEmployer.time.months`}
                    value={state.data.info[type].prevEmployer.time.months}
                    type="number"
                  />
                </Grid>
              </>
            ) : null}
          </Grid>
        </div>
      </div>
      <div
        style={{
          marginBottom: '10px',
          boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
          borderRadius: '3px',
          padding: '10px 15px 15px 15px',
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Text fontSize={16} fontStyle="italic" text="Other income note:" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <CurrencyField
              label="Gross Monthly Other Income"
              name={`data.info.${type}.grossMonthlyOtherIncome`}
              value={state.data.info[type].grossMonthlyOtherIncome}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Other income source"
              name={`data.info.${type}.otherIncomeSource`}
              value={state.data.info[type].otherIncomeSource}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
}
