import React, { useContext } from 'react';
import Cleave from 'cleave.js/react';

import { FormContext } from '../../../contexts/';

import { TextField as MaterialTextField } from '@material-ui/core/';
import { validateSchema } from 'validations';

interface Props {
  label: string;
  required?: boolean;
  name: string;
  value: Date | string | null;
  type?: string;
}

function DateInput(props: any) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      options={{
        date: true,
        datePattern: ['m', 'd', 'Y'],
      }}
      pattern=".{10,10}"
      {...rest}
    />
  );
}

export default function DatePickerField({
  label,
  required = false,
  name,
  value,
  type = 'text',
}: Props) {
  const [, setState] = useContext(FormContext);
  const { errors } = validateSchema(
    'new_external_credit_application',
    value,
    name?.split('.')
  );
  const error =
    errors && errors?.length > 0 && value !== undefined && value !== '';
  return (
    <MaterialTextField
      name={name}
      value={value}
      label={label}
      error={error}
      helperText={
        error
          ? errors?.reduce((acc, curr) => acc + '\n' + curr.message, '')
          : undefined
      }
      variant="outlined"
      size="small"
      required={required}
      fullWidth
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '7px',
      }}
      InputProps={{ inputComponent: DateInput }}
      InputLabelProps={{ shrink: true }}
      onChange={(event) => {
        setState({
          value: event.target.value !== '' ? event.target.value : null,
          name,
          type,
        });
      }}
    />
  );
}
