import React, { useContext } from 'react';
import { RadioGroup } from '../../components/Inputs';
import { FormContext } from '../../contexts/';

export default function CreditType() {
  const [state] = useContext(FormContext);

  return (
    <div
      style={{
        marginBottom: '10px',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
        borderRadius: '3px',
        padding: '10px 15px 10px 15px',
      }}
    >
      <RadioGroup
        radioOptions={[
          {
            value: 'individual',
            label: 'Individual - You are relying on your income or assets.',
          },
          {
            value: 'joint',
            label:
              'Joint - By choosing this option, you intend to apply for "joint credit."',
          },
        ]}
        required={true}
        name={`data.info.creditType`}
        value={state.data.info.creditType ?? 'individual'}
      />
    </div>
  );
}
