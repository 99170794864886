import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useContext } from 'react';
import { validateSchema } from 'validations';
import { FormContext } from '../../../contexts/';
import {
  Id,
  PropertyHolder,
  State,
  UnitStatus,
} from '../../../features/ApplicationForm/types';

type StringKeys<T> = {
  [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

interface Props<T> {
  label: string;
  labelProp: StringKeys<T>;
  valueProp: keyof T;
  options: T[];
  required?: boolean;
  name: string;
  value: any;
  disableClearable?: boolean;
}

export default function ComboField<
  T extends State | Id | PropertyHolder | UnitStatus
>({
  options,
  required = false,
  label,
  labelProp,
  value,
  name,
  valueProp,
  disableClearable = false,
}: Props<T>) {
  const [, setState] = useContext(FormContext);
  const { errors } = validateSchema(
    'new_external_credit_application',
    value,
    name?.split('.')
  );
  const error =
    errors && errors?.length > 0 && value !== undefined && value !== '';
  return (
    <Autocomplete
      options={options}
      autoSelect
      id={name}
      autoHighlight={false}
      disableClearable={disableClearable}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return (options.find((o) => o[valueProp] === value)?.[labelProp] ??
            '') as unknown as string;
        }
        return (option[labelProp] ?? '') as unknown as string;
      }}
      getOptionSelected={(option) => {
        if (value === '') return false;
        return option[valueProp] === value;
      }}
      onChange={(event, option: T | null) => {
        setState({
          //@ts-ignore
          value: (option?.[valueProp] as unknown as string) ?? null,
          name,
          type: 'text',
        });
      }}
      value={value !== '' ? value : null}
      renderInput={(params) => (
        <TextField
          error={error}
          name={name}
          helperText={
            error
              ? errors?.reduce((acc, curr) => acc + '\n' + curr.message, '')
              : undefined
          }
          required={required}
          {...params}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password',
          }}
          label={label}
          variant="outlined"
          size="small"
        />
      )}
    />
  );
}
