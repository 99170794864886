import States from '../utils/us-states';

export interface InitState {
  status: string | null;
  errorMessage: string | null;
}

export interface FormState {
  captchaToken: string | null;
  data: {
    dealershipId: string | null;
    info: {
      termsAccepted: boolean;
      creditType: 'individual' | 'joint' | null;
      applicant: ApplicantInfo;
      coApplicant: ApplicantInfo;
      collateral: Collateral;
      tradeIn: TradeIn;
      price: Price;
      // payoff: Payoff;
      // fees: Fees;
      // taxes: Taxes;
    };
  };
}

export interface ApplicantInfo {
  firstName: string | null;
  middleName: string | null;
  lastName: string | null;
  idType: string | null;
  idNumber: string | null;
  idIssuedBy: string | null;
  idExpDate: Date | string | null;
  idIssuedDate: Date | string | null;
  dateOfBirth: Date | string | null;
  ssn: string | null;
  primaryPhone: string | null;
  secondaryPhone: string | null;
  email: string | null;
  currentAddress: AddressInfo;
  residentialStatus: string | null;
  rentOrMortgageAmount: number | null;
  previousAddress: AddressInfo;
  employmentStatus: string | null;
  currentEmployer: CurrentEmployerInfo;
  prevEmployer: PrevEmployerInfo;
  grossMonthlyOtherIncome: number | null;
  otherIncomeSource: string | null;
  isUSCitizen: string | null;
  maritalStatus: 'Married' | 'Not married' | null;
  retirementIncome: number | null;
  sourceOfRetirementIncome: string | null;
}

export interface Collateral {
  vin: string | null;
  year: string | null;
  make: string | null;
  model: string | null;
  miles: string | null;
  unitStatus: (typeof UnitStatusValues)[number]['unit'] | null;
  MSRP?: number | null;
}
export interface Price {
  // price: number | null;
  totalCash: number | null;
  // tradeAllowance: number | null;
}
export interface Payoff {
  payoff: number | null;
  payoffBank: string | null;
}
export interface Taxes {
  stateTax: number | null;
}
export interface Fees {
  titleFees: number | null;
  docFee: number | null;
}
export interface TradeIn {
  vin: string | null;
  year: string | null;
  make: string | null;
  model: string | null;
  miles: string | null;
}

interface AddressInfo {
  address: string | null;
  addressNumber: string | null;
  apt: string | null;
  city: string | null;
  state: (typeof States)[number]['name'] | null;
  zip: string | null;
  time: { years: string | null; months: string | null };
}

export interface CurrentEmployerInfo {
  name: string | null;
  grossMonthlySalary: number | null;
  workPhone: string | null;
  occupationOrJobTitle: string | null;
  time: { years: string | null; months: string | null };
}

export interface PrevEmployerInfo {
  name: string | null;
  occupationOrJobTitle: string | null;
  time: { years: string | null; months: string | null };
}
export const UnitStatusValues = [
  {
    unit: 'new' as const,
  },
  {
    unit: 'used' as const,
  },
];
