export default function requestReducer(state: any, action: any) {
  switch (action.type) {
    case 'ON_SUCCESS':
      return { ...state, status: 'success' };
    case 'ON_LOADING':
      return { ...state, status: 'loading', errorMessage: null };
    case 'ON_ERROR':
      return { ...state, status: 'error', errorMessage: action.payload };
    case 'ON_RESET':
      return { status: null, errorMessage: null };
    default:
      return { status: null, errorMessage: null };
  }
}
