import { TextField as MaterialTextField } from '@material-ui/core/';
import React, { useContext } from 'react';
import { validateSchema } from 'validations';
import { FormContext } from '../../../contexts/';

interface Props {
  label: string;
  required?: boolean;
  disabled?: boolean;
  value?: string | null;
  name?: string;
  type?: string;
  min?: number;
}

export default function TextField({
  label,
  required = false,
  value,
  disabled = false,
  name,
  type = 'text',
  min,
}: Props) {
  const [, setState] = useContext(FormContext);
  const { errors } = validateSchema(
    'new_external_credit_application',
    value,
    name?.split('.')
  );
  const error =
    errors && errors?.length > 0 && value !== undefined && value !== '';
  return (
    <MaterialTextField
      name={name}
      type={type}
      label={label}
      disabled={disabled}
      error={error}
      helperText={
        error
          ? errors?.reduce((acc, curr) => acc + '\n' + curr.message, '')
          : undefined
      }
      variant="outlined"
      size="small"
      inputProps={
        min
          ? {
              min: min,
            }
          : {}
      }
      required={required}
      fullWidth
      style={{ backgroundColor: '#FFFFFF', borderRadius: '7px' }}
      value={value}
      onChange={(event) =>
        setState({
          value: event.target.value !== '' ? event.target.value : null,
          name,
          type,
        })
      }
    />
  );
}
