import produce from 'immer';
import { set } from 'lodash';
import { FormState } from '../../contexts/types';

export default function enhancedReducer(
  state: FormState,
  action: { _path: string[]; _value: any }
): FormState {
  const { _path, _value } = action;

  return produce(state, (draft) => {
    set(draft, _path, _value);
  });
}
