import React from 'react';
import { IconButton as MaterialIconButton } from '@material-ui/core/';

interface Props {
  icon: JSX.Element;
  handleFunc: () => void;
  disabled: boolean;
}

export default function IconButton({ icon, handleFunc, disabled }: Props) {
  return (
    <MaterialIconButton
      onClick={handleFunc}
      disabled={disabled}
      color="primary"
    >
      {icon}
    </MaterialIconButton>
  );
}
