import React from 'react';
import { Typography } from '@material-ui/core';

interface Props {
  fontSize: number;
  text: string;
  fontStyle: string;
}

export default function Text({ fontSize, text, fontStyle }: Props) {
  return <Typography style={{ fontSize, fontStyle }}>{text}</Typography>;
}
