import React, { useContext } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormContext } from '../../contexts/';

export default function Captcha() {
  const [, setState] = useContext(FormContext);

  const handleChange = (token: string | null) => {
    setState({ value: token, name: 'captchaToken', type: 'text' });
  };

  return (
    <ReCAPTCHA
      sitekey="6LcJzuAZAAAAANi6DVT79jjORjP2CPVipB6S6FUq"
      onChange={handleChange}
    />
  );
}
