import {
  Button,
  CircularProgress,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@material-ui/core/';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import React, { useContext, useEffect, useState } from 'react';
import { validateSchema } from 'validations';
import { FormContext, RequestContext } from '../../contexts/';
import {
  ApplicantInfo,
  Collateral,
  CreditType,
  LastStep,
} from '../../features/ApplicationForm';
import DealershipName from '../../features/ApplicationForm/DealershipName';
import { Alert } from '../DataDisplay';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    loader: {
      marginLeft: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    actionsContainer: {
      marginBottom: theme.spacing(2),
    },
    resetContainer: {
      padding: theme.spacing(3),
    },
  })
);

function getStepContent(step: number) {
  switch (step) {
    case 0:
      return <CreditType />;
    case 1:
      return <ApplicantInfo type="applicant" />;
    case 2:
      return <ApplicantInfo type="coApplicant" />;
    case 3:
      return <Collateral />;
    case 4:
      return <LastStep />;
    default:
      return <> </>;
  }
}

export default function VerticalLinearStepper() {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useContext(FormContext);
  const [requestState, dispatch] = useContext(RequestContext);
  const [errorState, setErrorState] = useState({
    open: false,
    message: '',
  });
  const steps = [
    { label: 'Credit type', number: 0 },
    { label: 'Applicant Information', number: 1 },
    ...(state.data.info.creditType === 'joint' ||
    (state.data.info.applicant.maritalStatus === 'Married' &&
      state.data.info.applicant.currentAddress.state === 'Wisconsin')
      ? [
          {
            label:
              state.data.info.creditType === 'joint'
                ? 'Co-Applicant Information'
                : 'Spouse Information',
            number: 2,
          },
        ]
      : []),
    { label: 'Collateral', number: 3 },
    { label: 'Send application', number: 4 },
  ];
  const handleNext = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const { valid, errors } = validateSchema(
      'new_external_credit_application',
      state
    );
    if (valid === false) {
      setErrorState({
        open: true,
        message:
          errors?.reduce((acc, curr) => acc + '\n' + curr.message, '') ??
          'Something went wrong!',
      });
    } else {
      const form = document.getElementById('form') as HTMLFormElement;
      if (form.checkValidity()) {
        e.preventDefault();
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      }
    }
  };
  const handleBack = () => {
    if (activeStep === steps.length - 1) {
      setState({
        value: null,
        name: 'captchaToken',
        type: 'text',
      });
      dispatch({ type: 'ON_RESET' });
    }

    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (requestState?.status === 'success') {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  }, [requestState.status]);

  return (
    <div className={classes.root}>
      <DealershipName />
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepLabel>
              <Typography style={{ fontSize: '17px', fontStyle: 'italic' }}>
                {step.label}
              </Typography>
            </StepLabel>
            <StepContent>
              <Alert
                errorState={errorState}
                setErrorState={setErrorState}
                severity="error"
              />
              {getStepContent(step.number)}
              <div className={classes.actionsContainer}>
                <div style={{ display: 'flex' }}>
                  <Button
                    id="back-button"
                    disabled={
                      activeStep === 0 || requestState?.status === 'loading'
                    }
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                  {activeStep === steps.length - 1 ? (
                    <Button
                      id="send-button"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={
                        state.data.info.termsAccepted
                          ? process.env.REACT_APP_ENV === 'prod'
                            ? state.captchaToken === null ||
                              requestState?.status === 'loading'
                            : false
                          : true
                      }
                      className={classes.button}
                    >
                      Send
                    </Button>
                  ) : (
                    <Button
                      id="next-button"
                      variant="contained"
                      color="primary"
                      type="submit"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                  <div style={{ marginTop: '2px' }}>
                    {requestState?.status === 'loading' && (
                      <CircularProgress className={classes.loader} size={30} />
                    )}
                  </div>
                </div>
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography style={{ color: '#03920C' }}>
            Your credit application has been submitted successfully!
          </Typography>
        </Paper>
      )}
    </div>
  );
}
