import { Dispatch } from 'react';
import { validateSchema } from 'validations';
import { ApplicantInfo, Collateral, TradeIn } from '../contexts/types';
import { axiosInstance } from './axiosInstance';

interface RequestData {
  data: {
    dealershipId: string | null;
    info: {
      creditType: string | null;
      applicant: ApplicantInfo;
      coApplicant?: ApplicantInfo;
      collateral: Collateral;
      tradeIn: TradeIn;
    };
  };
}

export default async function sendFormData(
  token: string,
  state: RequestData,
  dispatch: Dispatch<any>
) {
  let config = {
    headers: {
      Captcha: token,
    },
  };

  const creditType =
    state.data.info.creditType === 'joint' ||
    (state.data.info.applicant.maritalStatus === 'Married' &&
      state.data.info.applicant.currentAddress.state === 'Wisconsin')
      ? 'joint'
      : 'individual';

  const { coApplicant, ...newInfo } = state.data.info;

  const newState =
    creditType === 'individual'
      ? { ...state, data: { ...state.data, info: { ...newInfo, creditType } } }
      : {
          ...state,
          data: { ...state.data, info: { ...state.data.info, creditType } },
        };

  const { valid, errors } = validateSchema(
    'new_external_credit_application',
    newState
  );
  try {
    if (valid) {
      dispatch({ type: 'ON_LOADING' });
      await axiosInstance.post(
        '/api/external_credit_application',
        newState,
        config
      );
      dispatch({ type: 'ON_SUCCESS' });
    } else {
      dispatch({
        type: 'ON_ERROR',
        payload: errors,
      });
    }
  } catch (error) {
    dispatch({
      type: 'ON_ERROR',
      payload:
        //@ts-ignore
        error?.data?.error === 'captcha is not valid'
          ? 'Invalid verification captcha!'
          : 'An error has occurred, please try again later.',
    });
  }
}
