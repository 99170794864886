import React, { useContext } from 'react';
import sendFormData from '../../api/sendFormData';
import { Stepper } from '../../components/Stepper';
import { FormContext, RequestContext } from '../../contexts/';

export default function LayoutWrapper() {
  const [state] = useContext(FormContext);
  const [, dispatch] = useContext(RequestContext);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { captchaToken, ...rest } = state;

    if (['prod'].includes(process.env.REACT_APP_ENV ?? '')) {
      if (captchaToken !== null) {
        await sendFormData(captchaToken, rest, dispatch);
      }
    } else {
      await sendFormData('', rest, dispatch);
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ width: '660px' }}>
        <form onSubmit={handleSubmit} id="form">
          <Stepper />
        </form>
      </div>
    </div>
  );
}
