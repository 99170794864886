import React, { useContext } from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { FormContext, RequestContext } from '../../contexts';
import { Captcha, NoticeAndTerms } from './';

export default function LastStep() {
  const [requestState] = useContext(RequestContext);
  const [state, setState] = useContext(FormContext);

  return (
    <div>
      <Captcha />
      <div style={{ margin: '10px' }}>
        <FormControlLabel
          onChange={() => {
            setState({
              value: !state.data.info.termsAccepted,
              name: `data.info.termsAccepted`,
              type: 'text',
            });
          }}
          control={
            <Checkbox id={'gdpr'} checked={state.data.info.termsAccepted} />
          }
          label={
            <Typography style={{ fontSize: '12px' }}>
              I authorize Web Finance Direct, Inc. to obtain my credit report in
              support of my loan application. By checking this box, I
              acknowledge and consent to Web Finance Direct, Inc. accessing my
              credit report from one or more consumer reporting agencies for the
              purpose of processing my loan application. I understand that this
              authorization is voluntary and that the information obtained may
              be used solely for support of processing my application. I
              understand that Web Finance Direct, Inc. will use the information
              obtained in accordance with applicable laws and regulations, and
              will maintain the confidentiality and security of my personal
              information. I acknowledge that I have the right to revoke this
              authorization at any time by contacting Web Finance Direct, Inc.
              in writing. However, I understand that revoking this authorization
              may affect the processing of my loan application.
            </Typography>
          }
        />
      </div>
      {requestState?.status === 'error' && (
        <Typography
          style={{ fontStyle: 'italic', color: '#F8442A', marginTop: '10px' }}
        >
          {requestState?.errorMessage}
        </Typography>
      )}
      <NoticeAndTerms />
    </div>
  );
}
