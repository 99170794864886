import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Alert as MaterialAlert } from '@material-ui/lab/';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: '5px',
      left: '20vw',
      width: '60%',
      zIndex: 9999999,
    },
  })
);

interface State {
  open: boolean;
  message: string;
}

interface Props {
  errorState: State;
  setErrorState: (x: State) => void;
  severity: 'success' | 'info' | 'warning' | 'error' | undefined;
}

export default function Alert({ errorState, setErrorState, severity }: Props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Collapse in={errorState.open}>
        <MaterialAlert
          severity={severity}
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={() => {
                setErrorState({ ...errorState, open: false, message: '' });
              }}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          }
        >
          {errorState.message}
        </MaterialAlert>
      </Collapse>
    </div>
  );
}
