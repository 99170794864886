import { Paper, Typography } from '@material-ui/core';
import React, { useState, useContext, useEffect } from 'react';
import getDealershipNameById from '../../api/getDealershipName';
import { FormContext } from '../../contexts/';

interface DealershipNameResponseData {
  result?: { name: string };
  status: string;
}

export default function DealershipName() {
  const [state] = useContext(FormContext);

  const [dealershipState, setDealershipState] =
    useState<DealershipNameResponseData>({
      result: { name: '' },
      status: '',
    });

  useEffect(() => {
    const fetchData = async () => {
      if (state.data.dealershipId) {
        const response: DealershipNameResponseData =
          await getDealershipNameById(state.data.dealershipId);
        setDealershipState(response);
      }
    };

    fetchData();
  }, [state.data.dealershipId]);

  return dealershipState?.status === 'success' ? (
    <Paper
      elevation={2}
      style={{
        marginTop: '20px',
        marginBottom: '20px',
        textAlign: 'center',
        padding: '10px 20px 10px',
      }}
    >
      <Typography variant="h4">{dealershipState?.result?.name}</Typography>
    </Paper>
  ) : (
    <> </>
  );
}
