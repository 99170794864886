import React, { useContext } from 'react';
import { TextField as MaterialTextField } from '@material-ui/core/';
import Cleave from 'cleave.js/react';
import { FormContext } from '../../../contexts/';
import { validateSchema } from 'validations';

interface Props {
  label: string;
  required?: boolean;
  name: string;
  value: number | null;
}

function NumberInput(props: any) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      options={{
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        prefix: '$ ',
      }}
      {...rest}
    />
  );
}

export default function CurrencyField({
  label,
  required = false,
  name,
  value,
}: Props) {
  const [, setState] = useContext(FormContext);
  const { errors } = validateSchema(
    'new_external_credit_application',
    value,
    name?.split('.')
  );
  const error = errors && errors?.length > 0 && value !== undefined;

  return (
    <MaterialTextField
      error={error}
      helperText={
        error
          ? errors?.reduce((acc, curr) => acc + '\n' + curr.message, '')
          : undefined
      }
      name={name}
      label={label}
      variant="outlined"
      size="small"
      required={required}
      fullWidth
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '7px',
      }}
      InputLabelProps={{ shrink: true }}
      InputProps={{ inputComponent: NumberInput }}
      value={value}
      onChange={(event) => {
        setState({
          value: parseFloat(event.target.value.replace(/[$, \\,]/g, '')) || 0,
          name,
          type: 'text',
        });
      }}
    />
  );
}
