import React, { useContext } from "react";
import {
  Radio,
  RadioGroup as MaterialRadioGroup,
  FormControlLabel,
  FormLabel,
  Box,
} from "@material-ui/core/";
import { FormContext } from "../../contexts/";

interface Props {
  radioOptions: RadioOption[];
  row?: boolean;
  label?: null | string;
  required?: boolean;
  name: string;
  value: string;
}

interface RadioOption {
  value: string;
  label: string;
}

export default function RadioGroup({
  radioOptions,
  row = false,
  label = null,
  required = false,
  name,
  value,
}: Props) {
  const [, setState] = useContext(FormContext);

  return (
    <Box
      style={{
        border: "1px solid #C2C4C4",
        borderRadius: "4px",
        padding: "0px 10px 0px 10px",
      }}
    >
      {label !== null && (
        <FormLabel
          required={required}
          component="legend"
          style={{
            fontStyle: "italic",
            color: "#212121",
            margin: "10px 0px 5px 0px",
          }}
        >
          {label}
        </FormLabel>
      )}
      <MaterialRadioGroup
        name={name}
        row={row}
        value={value}
        onChange={(event) => setState(event.target)}
      >
        {radioOptions.map((el: RadioOption, index: number) => {
          return (
            <FormControlLabel
              key={index}
              value={el.value}
              control={
                <Radio size="small" color="primary" required={required} />
              }
              label={el.label}
            />
          );
        })}
      </MaterialRadioGroup>
    </Box>
  );
}
