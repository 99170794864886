import React from 'react';

const legalTexts = [
  {
    title: ' Privacy Notice',
    divId: 'dms_default_privacy_policy',
    content: (
      <div id="dms_default_privacy_policy">
        <div className="dms_default_privacy_policy">
          <p>
            <b style={{ fontSize: '25px' }}>Web Finance Direct</b>
            <br />
            725 N Progress Drive, Saukville WI 53080
          </p>
          <table id="testTable">
            <tbody>
              <tr>
                <td className="table1_col1">FACTS</td>
                <td className="table1_col2">
                  WHAT DOES WEB FINANCE DIRECT DO WITH YOUR PERSONAL
                  INFORMATION?
                </td>
              </tr>
              <tr>
                <td className="table1_col1">Why?</td>
                <td className="table1_col2">
                  Financial companies choose how they share your personal
                  information.
                  <br />
                  <br />
                  Federal law gives consumers the right to limit some but not
                  all sharing.
                  <br />
                  <br />
                  Federal law also requires us to tell you how we collect,
                  share, and protect your personal information. Please read this
                  notice carefully to understand what we do.
                </td>
              </tr>
              <tr>
                <td className="table1_col1">What?</td>
                <td className="table1_col2">
                  The types of personal information we collect and share depend
                  on the products or services you have with us. This information
                  can include:
                  <ul
                    style={{
                      margin: '5px',
                      padding: '15px',
                      listStyleType: 'square',
                    }}
                  >
                    <li>Social Security Number and income</li>
                    <li>Credit history and credit scores</li>
                    <li>
                      Employment information and credit card or other debt
                    </li>
                  </ul>
                  When you are no longer our customer, we continue to share your
                  information as described in this notice.
                </td>
              </tr>
              <tr>
                <td className="table1_col1">How?</td>
                <td className="table1_col2">
                  All financial companies need to share customers's personal
                  information to run their everyday business. In the section
                  below, we list the reasons financial companies can share their
                  customers's information; the reasons Web Finance Direct
                  chooses to share; and whether you can limit this sharing.
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <thead>
              <tr>
                <td className="table2_header_50">
                  Reasons we can share your personal information
                </td>
                <td className="table2_header">
                  Does Web Finance Direct share?
                </td>
                <td className="table2_header">Can you limit this sharing?</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>For our everyday business purposes&nbsp;-&nbsp;</b>
                  </p>
                  <p>
                    Such as to process your transactions, maintain your
                    account(s), respond to court orders and legal
                    investigations, or report to credit bureaus.
                  </p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <b>Yes</b>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <b>No</b>
                </td>
              </tr>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>For our marketing purposes&nbsp;-&nbsp;</b>
                  </p>
                  <p>to offer products and services to you</p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <b>Yes</b>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <b>No</b>
                </td>
              </tr>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>For joint marketing with other financial companies</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <p>
                    <b>No</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <p>
                    <b>We don't share</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>
                      For our affiliates' everyday business
                      purposes&nbsp;-&nbsp;
                    </b>
                  </p>
                  <p>information about your transactions and experiences</p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <p>
                    <b>No</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <p>
                    <b>We don't share</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>
                      For our affiliates' everyday business
                      purposes&nbsp;-&nbsp;
                    </b>
                  </p>
                  <p>information about your creditworthiness</p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <p>
                    <b>No</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <p>
                    <b>We don't share</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>For our affiliates to market to you</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <p>
                    <b>No</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <p>
                    <b>We don't share</b>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="lt_gray">
                  <p>
                    <b>For our nonaffiliates to market to you</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">
                    Does Web Finance Direct share?
                  </div>
                  <p>
                    <b>No</b>
                  </p>
                </td>
                <td>
                  <div className="repeat_col">Can you limit this sharing?</div>
                  <p>
                    <b>We don't share</b>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table3_header_25">To limit our sharing</td>
                <td className="table3_header">
                  <ul>
                    <li>Mail the form below.</li>
                  </ul>
                  <p>
                    <b>Please note:</b>
                  </p>
                  <p>
                    If you are a <i>new</i> customer, we can begin sharing your
                    information 30 days from the date we sent this
                    notice.&nbsp;&nbsp;When you are <i>no longer</i> our
                    customer, we continue to share your information as described
                    in this notice.
                    <br />
                    <br />
                    However, you can contact us any time to limit our sharing.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table4_header">Mail-in Form</td>
              </tr>
              <tr>
                <td className="table4_row">
                  Mark any/all you want to limit:
                  <ul>
                    <li className="checkno">
                      [ ] Do not share information about my creditworthiness
                      with your affiliates for their everyday business purposes.
                    </li>
                    <li className="checkno">
                      [ ] Do not allow your affiliates to use my personal
                      information to market to me.
                    </li>
                    <li className="checkno">
                      [ ] Do not share my personal information with
                      nonaffiliates to market their products and services to me.
                    </li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table5_header_25">
                  Name
                  <p style={{ width: '100%', marginBottom: '10px' }}></p>
                  <br />
                  Address
                  <p style={{ width: '100%', marginBottom: '10px' }}></p>
                  <br />
                  City, State, Zip
                  <p style={{ width: '100%', marginBottom: '10px' }}></p>
                  <br />
                </td>
                <td className="table5_line_row">
                  <br />
                  <p></p>
                  <br />
                  <p></p>
                  <br />
                  <p></p>
                  <br />
                </td>
                <td className="table5_header_25w">
                  <b>Mail To:</b>
                  <br />
                  Web Finance Direct <br />
                  725 N Progress Drive
                  <br />
                  Saukville WI 53080
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table4_header" colSpan={2}>
                  <p style={{ fontWeight: 'bold' }}>Who We Are</p>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">
                  Who is providing this notice?
                </td>
                <td>
                  Web Finance Direct
                  <p></p>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table4_header" colSpan={2}>
                  <p style={{ fontWeight: 'bold' }}>What we do</p>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">
                  How does Web Finance Direct protect my personal information?
                </td>
                <td>
                  To protect your personal information from unauthorized access
                  and use, we use security measures that comply with federal
                  law. These measures include computer safeguards and secured
                  files and buildings.
                  <p></p>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">
                  How does Web Finance Direct collect my personal information?
                  <p></p>
                </td>
                <td>
                  We may collect your personal information, for example, when
                  you
                  <ul
                    style={{
                      margin: '5px',
                      padding: '15px',
                      listStyleType: 'square',
                    }}
                  >
                    <li>Show your driver's license</li>
                    <li>Apply for financing and apply for a lease</li>
                    <li>
                      Provide your employment information and give us your
                      employment history
                    </li>
                  </ul>
                  <p>
                    We also collect your personal information from other sources
                    such as credit bureaus, affiliates, or other companies.
                  </p>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">
                  Why can't I limit all sharing?
                </td>
                <td>
                  Federal law gives you the right to limit only
                  <ul
                    style={{
                      margin: '5px',
                      padding: '15px',
                      listStyleType: 'square',
                    }}
                  >
                    <li>
                      Sharing for affiliates' everyday business
                      purposes&nbsp;-&nbsp;information about your
                      creditworthiness
                    </li>
                    <li>
                      Affiliates from using your information to market to you
                    </li>
                    <li>Sharing for nonaffiliates to market to you</li>
                  </ul>
                  <p>
                    State laws and individual companies may give you additional
                    rights to limit sharing.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table4_header" colSpan={2}>
                  <p style={{ fontWeight: 'bold' }}>Definitions</p>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">Affiliates</td>
                <td>
                  Companies related by common ownership or control. They can be
                  financial and nonfinancial companies.
                  <ul
                    style={{
                      margin: '5px',
                      padding: '15px',
                      listStyleType: 'square',
                    }}
                  >
                    <li>
                      Web Finance Direct does not share with our affiliates
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">Nonaffiliates</td>
                <td>
                  Companies not related by common ownership or control. They can
                  be financial and nonfinancial companies.
                  <ul
                    style={{
                      margin: '5px',
                      padding: '15px',
                      listStyleType: 'square',
                    }}
                  >
                    <li>
                      Web Finance Direct does not share with nonaffiliates so
                      they can market to you
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td className="table_25 lt_gray">Joint Marketing</td>
                <td>
                  A formal agreement between nonaffiliated financial companies
                  that together market financial products or services to you.
                  <ul
                    style={{
                      margin: '5px',
                      padding: '15px',
                      listStyleType: 'square',
                    }}
                  >
                    <li>Web Finance Direct does not jointly market</li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <table>
            <tbody>
              <tr>
                <td className="table6_col1 table_25">Questions?</td>
                <td>&nbsp;&nbsp;Call (608) 519-3700</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    ),
  },
  {
    title: 'Terms & Conditions',
    divId: 'cx-terms-conditions',
    content: (
      <div className="cx-terms-conditions" id="cx-terms-conditions">
        <ul>
          <li>
            You agree to receive important notices and other communications at
            any telephone number or email address (including a mobile device)
            you entered so that Web Finance Direct or one of its third party
            associates can reach you regarding this request. This authorization
            removes any previous registration(s) on a federal or state
            Do-Not-Call (DNC) registry or any internal Web Finance Direct
            opt-out/unsubscribe requests you may have previously requested.
          </li>
          <br />
          <li>
            That Web Finance Direct or one of its third party associates may use
            an automatic dialing system in connection with calls made to any
            telephone number you entered, even if it is a cellular phone number
            or other service for which the called person(s) could be charged for
            such call.
          </li>
          <br />
          <li>
            You are providing written consent under the Fair Credit Report Act
            for Web Finance Direct and its lenders with whom you are matched to
            obtain consumer report "hard inquiry" information from your credit
            profile or other information from our contracted Credit Bureau.
            Lenders will contact you directly to discuss your loan options as
            well as to obtain additional financial information.
          </li>
        </ul>
      </div>
    ),
  },
];

export default legalTexts;
