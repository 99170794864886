import { createContext, Dispatch } from 'react';
import { InitState } from '../contexts/types';

export const initialState: InitState = {
  status: null,
  errorMessage: null,
};

export const RequestContext = createContext<[InitState, Dispatch<any>]>([
  initialState,
  () => {},
]);
