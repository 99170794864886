import React, { Fragment, SyntheticEvent, useState } from 'react';
import { Link } from '@material-ui/core';
import { Dialog } from '../../components/Feedback/Dialog';
import legalTexts from '../../utils/legalTexts';

export default function NoticeAndTerms() {
  const [modalState, setModalState] = useState<{
    open: boolean;
    index: null | number;
  }>({
    open: false,
    index: null,
  });

  const handleOpen = (event: SyntheticEvent, index: number) => {
    event.preventDefault();

    setModalState({ ...modalState, open: true, index });
  };

  return (
    <div
      style={{
        fontSize: '15px',
        fontStyle: 'italic',
        marginTop: '15px',
        marginBottom: '10px',
      }}
    >
      By submitting this Credit Application, you acknowledge receipt of our
      {legalTexts.map((el: any, index: number) => {
        return (
          <Fragment key={index}>
            <Link
              href="#"
              onClick={(event: SyntheticEvent) => handleOpen(event, index)}
            >
              {el.title}
            </Link>
            {index === 0 && ' and '}
          </Fragment>
        );
      })}
      <Dialog
        title={legalTexts[modalState.index as number]?.title}
        divId={legalTexts[modalState.index as number]?.divId}
        modalState={modalState}
        setModalState={setModalState}
        content={legalTexts[modalState.index as number]?.content}
      />
    </div>
  );
}
