import { useReducer, Dispatch } from 'react';
import { initialState } from '../contexts/RequestContext';
import { requestReducer } from '../utils/reducers/';
import { InitState } from '../contexts/types';

export default function RequestStateManager(): [InitState, Dispatch<any>] {
  const [state, dispatch] = useReducer(requestReducer, initialState);

  return [state, dispatch];
}
