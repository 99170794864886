import React, { useContext } from 'react';
import { TextField as MaterialTextField } from '@material-ui/core/';
import Cleave from 'cleave.js/react';
import { FormContext } from '../../../contexts/';
import { validateSchema } from 'validations';

interface Props {
  label: string;
  required?: boolean;
  name: string;
  value: string | null;
}

function SSNInput(props: any) {
  const { inputRef, ...rest } = props;
  return (
    <Cleave
      options={{
        numericOnly: true,
        blocks: [0, 3, 2, 4],
        delimiters: ['', '-', '-'],
      }}
      pattern=".{11,11}"
      {...rest}
    />
  );
}

export default function SsnField({
  label,
  required = false,
  name,
  value,
}: Props) {
  const [, setState] = useContext(FormContext);
  const { errors } = validateSchema(
    'new_external_credit_application',
    value,
    name?.split('.')
  );
  const error =
    errors && errors?.length > 0 && value !== undefined && value !== '';

  return (
    <MaterialTextField
      error={error}
      helperText={
        error
          ? errors?.reduce((acc, curr) => acc + '\n' + curr.message, '')
          : undefined
      }
      name={name}
      value={value}
      label={label}
      variant="outlined"
      size="small"
      required={required}
      fullWidth
      style={{
        backgroundColor: '#FFFFFF',
        borderRadius: '7px',
      }}
      InputProps={{ inputComponent: SSNInput }}
      onChange={(event) =>
        setState({
          value:
            event.target.value !== ''
              ? event.target.value?.replace(/[- ]/g, '')
              : null,
          name,
          type: 'text',
        })
      }
    />
  );
}
