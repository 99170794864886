import { Grid, Typography } from '@material-ui/core';
import React, { useContext } from 'react';
import { Text } from '../../components/DataDisplay';
import {
  ComboField,
  CurrencyField,
  TextField,
} from '../../components/Inputs/Fields';
import { FormContext } from '../../contexts';
import { UnitStatusValues } from '../../contexts/types';
import { UnitStatus } from './types';

export default function Collateral() {
  const [state] = useContext(FormContext);

  return (
    <div
      style={{
        marginBottom: '10px',
        boxShadow: '0 3px 6px rgba(0,0,0,0.16)',
        borderRadius: '3px',
        padding: '15px',
      }}
    >
      <Typography variant="h6">
        Vehicle (Collateral) you are interested in?
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Vin"
            name={`data.info.collateral.vin`}
            value={state.data.info.collateral.vin}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Year"
            type="number"
            required={true}
            name={`data.info.collateral.year`}
            min={1900}
            value={state.data.info.collateral.year}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Make"
            name={`data.info.collateral.make`}
            required={true}
            value={state.data.info.collateral.make}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Model"
            name={`data.info.collateral.model`}
            required={true}
            value={state.data.info.collateral.model}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Miles"
            type="number"
            name={`data.info.collateral.miles`}
            required={true}
            value={state.data.info.collateral.miles}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ComboField<UnitStatus>
            labelProp="unit"
            valueProp="unit"
            label="Unit Status"
            options={UnitStatusValues}
            name={`data.info.collateral.unitStatus`}
            value={state.data.info.collateral.unitStatus}
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          <CurrencyField
            label="Cash Down"
            name={`data.info.price.totalCash`}
            value={state.data.info.price.totalCash ?? null}
          />
        </Grid>
        <Grid item xs={12}>
          <Text fontSize={16} fontStyle="italic" text="Trade-in" />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextField
            label="Vin"
            name={`data.info.tradeIn.vin`}
            value={state.data.info.tradeIn.vin}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Year"
            type="number"
            name={`data.info.tradeIn.year`}
            value={state.data.info.tradeIn.year}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Make"
            name={`data.info.tradeIn.make`}
            value={state.data.info.tradeIn.make}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Model"
            name={`data.info.tradeIn.model`}
            value={state.data.info.tradeIn.model}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextField
            label="Miles"
            type="number"
            name={`data.info.tradeIn.miles`}
            value={state.data.info.tradeIn.miles}
          />
        </Grid>
        {/* <Grid item xs={12}>
          <Text fontSize={16} fontStyle="italic" text="Financial info" />
        </Grid>
        <Grid item xs={6} sm={6}>
          <CurrencyField
            label="Sale Price"
            name={`data.info.price.price`}
            value={state.data.info.price.price ?? null}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <CurrencyField
            label="Cash Down"
            name={`data.info.price.totalCash`}
            value={state.data.info.price.totalCash ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyField
            label="Trade Allowance"
            name={`data.info.price.tradeAllowance`}
            value={state.data.info.price.tradeAllowance ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyField
            label="Payoff"
            required={Number(state.data.info.price.tradeAllowance) > 0}
            name={`data.info.payoff.payoff`}
            value={state.data.info.payoff.payoff ?? null}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            label="Payoff bank"
            type="text"
            required={Number(state.data.info.payoff?.payoff) > 0}
            name={`data.info.payoff.payoffBank`}
            value={state.data.info.payoff.payoffBank}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <CurrencyField
            label="Title/Transfer Fees"
            name={`data.info.fees.titleFees`}
            value={state.data.info.fees.titleFees ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyField
            label="Doc Fee"
            name={`data.info.fees.docFee`}
            value={state.data.info.fees.docFee ?? null}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CurrencyField
            label="State Tax"
            name={`data.info.taxes.stateTax`}
            value={state.data.info.taxes.stateTax ?? null}
          />
        </Grid> */}
      </Grid>
    </div>
  );
}
